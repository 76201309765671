// Generated file, do not edit.
// Edit the json files in the designTokens directory and run the build-design-tokens.js script.

export default {
    optionsBrandColorBlack: '#171717',
    optionsBrandColorOffWhite: '#EFEFEF',
    optionsBrandColorBlue: '#00F0FF',
    optionsBrandColorDarkBlue: '#2068D3',
    optionsBrandColorBlue2: '#1A63D1',
    optionsBrandColorLimboGradient: 'linear-gradient(180deg, #B8B8B8 0%, #6F6F6F 100%)',
    optionsBrandColorGrey: '#424547',
    optionsBrandColorColor3: '#0E74BA',
    optionsBrandColorColor4: '#2989F1',
    optionsBrandColorColor5: '#007B4D',
    optionsBrandColorColor6: '#20A954',
    optionsBrandColorColor7: '#63E761',
    optionsBrandColorColor8: '#F192BC',
    optionsBrandColorColor9: '#E74033',
    optionsBrandColorColor10: '#D0A045',
    optionsBrandColorColor11: '#F0CA00',
    optionsBrandColorColor12: '#FFEB3B',
    optionsBrandColorIndigoBlue: '#34428A',
    optionsBrandColorAzureBlue: '#0E74BA',
    optionsBrandColorForestGreen: '#007B4D',
    optionsBrandColorParakeetGreen: '#20A954',
    optionsBrandColorCrimsonRed: '#E74033',
    optionsBrandColorPunchPink: '#EB5B7F',
    optionsBrandColorPearlPink: '#F192BC',
    optionsBrandColorHoneyYellow: '#F0CA00',
    optionsBrandColorPumpkinOrange: '#F0821F',
    optionsBrandColorGreen: '#007B4D',
    optionsBrandColorRed: '#E74033',
    optionsBrandColorYellow: '#D0A045',
    optionsBrandColorWhite: '#FFFFFF',
    optionsBrandColorXlcSand: '#A1A1A1',
    optionsBrandColorXlcGrey: '#6A798C',
    optionsBrandColorBlueGradient: 'linear-gradient(180deg, #002AB0 0%, #002495 100%)',
    optionsBrandColorGreenGradient: 'linear-gradient(180deg, #019765 0%, #007B4D 100%)',
    optionsBrandColorYellowGradient: 'linear-gradient(180deg, #FCD200 0%, #FCC800 100%)',
    optionsBrandColorXlcBlue: '#0052cc',
    optionsBrandColorXlcBlack: '#000000',
    optionsNeutralColorBlack: '#000000',
    optionsNeutralColorGrey900: '#121212',
    optionsNeutralColorGrey800: '#181818',
    optionsNeutralColorGrey750: '#1E1E1E',
    optionsNeutralColorGrey700: '#333333',
    optionsNeutralColorGrey600: '#4F4F4F',
    optionsNeutralColorGrey500: '#727272',
    optionsNeutralColorGrey400: '#999999',
    optionsNeutralColorGrey300: '#6A798C',
    optionsNeutralColorGrey200: '#E3E3E3',
    optionsNeutralColorGrey100: '#F3F3F3',
    optionsNeutralColorGrey50: '#F9F9F9',
    optionsNeutralColorGrey150: '#F8F8F8',
    optionsNeutralColorWhite: '#FFFFFF',
    optionsNeutralColorGrey: '#E5E5E5',
    optionsFormColorCheck: '#0073BE',
    optionsStatusColorSuccessLight: '#F0FDF5',
    optionsStatusColorSuccessMain: '#21A153',
    optionsStatusColorSuccessDark: '#21A153',
    optionsStatusColorWarningLight: '#FEFCE9',
    optionsStatusColorWarningMain: '#ffb75d',
    optionsStatusColorWarningDark: '#975A12',
    optionsStatusColorErrorLight: '#FEF3F2',
    optionsStatusColorErrorMain: '#c23934',
    optionsStatusColorErrorDark: '#AE201A',
    optionsStatusColorInfoLight: '#EFF6FF',
    optionsStatusColorInfoMain: '#0073BE',
    optionsStatusColorInfoDark: '#1244CA',
    optionsFontFamilySansSerifOne: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    optionsFontFamilySansSerifTwo: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    optionsFontFamilySansSerifThree: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    optionsFontWeightLight: '400',
    optionsFontWeightRegular: '400',
    optionsFontWeightBold: '500',
    optionsFontWeightExtraBold: '700',
    optionsTextTransformNormal: 'none',
    optionsTextTransformLowercase: 'lowercase',
    optionsTextTransformUppercase: 'uppercase',
    optionsLetterSpacingNormal: '0',
    optionsLetterSpacingWide: '.075em',
    optionsLetterSpacingMediumWide: '.1em',
    optionsLetterSpacingExtraWide: '.125em',
    optionsMediaQueryXs: 576,
    optionsMediaQueryS: 768,
    optionsMediaQueryM: 992,
    optionsMediaQueryL: 1200,
    optionsMediaQueryXl: 1600,
    breakpointExtraSmall: 576,
    breakpointSmall: 768,
    breakpointMedium: 992,
    breakpointLarge: 1200,
    breakpointExtraLarge: 1600,
    fontTitleFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontTitleWeight: '500',
    fontTitleTransform: 'uppercase',
    fontTitleLetterSpacing: '0',
    fontTitleHighlightFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontTitleHighlightWeight: '700',
    fontTitleHighlightTransform: 'uppercase',
    fontTitleHighlightLetterSpacing: '0',
    fontHeadlineOneFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontHeadlineOneWeight: '700',
    fontHeadlineOneTransform: 'uppercase',
    fontHeadlineOneLetterSpacing: '0',
    fontHeadlineTwoFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontHeadlineTwoWeight: '500',
    fontHeadlineTwoTransform: 'none',
    fontHeadlineTwoLetterSpacing: '0',
    fontHeadlineThreeFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontHeadlineThreeWeight: '500',
    fontHeadlineThreeTransform: 'none',
    fontHeadlineThreeLetterSpacing: '0',
    fontSubHeadlineFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontSubHeadlineWeight: '500',
    fontSubHeadlineTransform: 'none',
    fontSubHeadlineLetterSpacing: '0',
    fontTitleOverlayFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontTitleOverlayWeight: '400',
    fontTitleOverlayTransform: 'none',
    fontTitleOverlayLetterSpacing: '0',
    fontMainNavItemFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontMainNavItemWeight: '500',
    fontMainNavItemTransform: 'none',
    fontMainNavItemLetterSpacing: '0',
    fontBodyFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontBodyWeight: '400',
    fontBodyTransform: 'none',
    fontBodyLetterSpacing: '0',
    fontCaptionFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontCaptionWeight: '400',
    fontCaptionTransform: 'none',
    fontCaptionLetterSpacing: '0',
    fontButtonFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontButtonWeight: '500',
    fontButtonWeightSmall: '400',
    fontButtonTransform: 'none',
    fontButtonLetterSpacing: '0',
    fontLinkFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontLinkWeight: '400',
    fontLinkTransform: 'none',
    fontLinkLetterSpacing: '0',
    fontPriceFamily: "Helvetica Neue, 'Helvetica Neue', sans-serif",
    fontPriceWeight: '400',
    fontPriceTransform: 'none',
    fontPriceLetterSpacing: '0',
    fontHeroTitleLetterSpacing: '0',
    fontLineHeightExtraSmall: '0.875',
    fontLineHeightSmall: '1',
    fontLineHeightMedium: '1.15',
    fontLineHeightLarge: '1.5',
    fontPlpCategoryLabelsTextTransform: 'none',
    fontPlpCategoryLabelsFontWeight: '400',
    fontPlpUpillSortingTextTransform: 'none',
    fontPlpUpillSortingFontWeight: '400',
    sizeTextHeadlineSizeOneSmall: '2.8rem',
    sizeTextHeadlineSizeOneMedium: '3.2rem',
    sizeTextHeadlineSizeTwoSmall: '2.4rem',
    sizeTextHeadlineSizeTwoMedium: '2.8rem',
    sizeTextHeadlineSizeThreeSmall: '2.2rem',
    sizeTextHeadlineSizeThreeMedium: '2.4rem',
    sizeTextHeadlineSizeFourSmall: '2rem',
    sizeTextHeadlineSizeFourMedium: '2.2rem',
    sizeTextHeadlineSizeFiveSmall: '1.8rem',
    sizeTextHeadlineSizeFiveMedium: '2rem',
    sizeTextHeadlineSizeSixSmall: '1.8rem',
    sizeTextHeadlineSizeSixMedium: '1.6rem',
    sizeTextOverlayTitleExtraSmall: 'calc((1rem + 0.25vw) * 2.2)',
    sizeTextOverlayTitleSmall: 'calc((1rem + 0.25vw) * 4)',
    sizeTextOverlayTitleMedium: 'calc((1rem + 0.25vw) * 5.6)',
    sizeTextOverlayTitleLarge: 'calc((1rem + 0.25vw) * 7.2)',
    sizeTextBodyExtraSmall: '1.2rem',
    sizeTextBodySmall: '1.4rem',
    sizeTextBodyMedium: '1.6rem',
    sizeTextBodyLarge: '1.8rem',
    sizeTextBodyExtraLarge: '2.4rem',
    sizeGridSpacingBlockMedium: '4rem',
    sizeGridSpacingBlockLarge: '8rem',
    sizeGridSpacingRem2: '0.2rem',
    sizeGridSpacingRem4: '0.4rem',
    sizeGridSpacingRem6: '0.6rem',
    sizeGridSpacingRem8: '0.8rem',
    sizeGridSpacingRem10: '1rem',
    sizeGridSpacingRem12: '1.2rem',
    sizeGridSpacingRem14: '1.4rem',
    sizeGridSpacingRem16: '1.6rem',
    sizeGridSpacingRem20: '2rem',
    sizeGridSpacingRem24: '2.4rem',
    sizeGridSpacingRem28: '2.8rem',
    sizeGridSpacingRem32: '3.2rem',
    sizeGridSpacingRem36: '3.6rem',
    sizeGridSpacingRem40: '4rem',
    sizeGridSpacingRem44: '4.4rem',
    sizeGridSpacingRem48: '4.8rem',
    sizeGridSpacingRem52: '5.2rem',
    sizeGridSpacingRem56: '5.6rem',
    sizeGridSpacingRem60: '6rem',
    sizeGridSpacingRem64: '6.4rem',
    sizeGridLayoutRem24: '2.4rem',
    sizeGridLayoutRem32: '3.2rem',
    sizeGridLayoutRem40: '4rem',
    sizeGridLayoutRem48: '4.8rem',
    sizeGridLayoutRem56: '5.6rem',
    sizeGridLayoutRem64: '6.4rem',
    sizeGridLayoutRem72: '7.2rem',
    sizeGridLayoutRem80: '8rem',
    sizeGridLayoutRem88: '8.8rem',
    sizeGridLayoutRem96: '9.6rem',
    sizeGridLayoutRem104: '10.4rem',
    sizeGridLayoutRem120: '12rem',
    sizeGridLayoutMaxWidthXxs: '32rem',
    sizeGridLayoutMaxWidthXs: '37.5rem',
    sizeGridLayoutMaxWidthS: '37.5rem',
    sizeGridLayoutMaxWidthM: '61.6rem',
    sizeGridLayoutMaxWidthL: '86.4rem',
    sizeGridLayoutMaxWidthXl: '160rem',
    sizeGridLayoutMaxWidthXxl: '160rem',
    sizeOtherIconXxs: '1.2rem',
    sizeOtherIconXs: '1.6rem',
    sizeOtherIconS: '2rem',
    sizeOtherIconM: '2.4rem',
    sizeOtherIconL: '3.2rem',
    sizeOtherIconXl: '4rem',
    sizeOtherBorderRadiusS: '0rem',
    sizeOtherBorderRadiusM: '0rem',
    sizeOtherBorderRadiusL: '1.6rem',
    sizeOtherBorderRadiusFull: '99rem',
    sizeOtherBorderRadiusButton: '0rem',
    sizeOtherBorderWidthS: '0.1rem',
    sizeOtherBorderWidthXs: '0.1rem',
    sizeOtherLogoWidth: '15.8rem',
    sizeOtherLogoHeight: '1.6rem',
    themeColorsPrimaryHeaderNavLink: '',
    themeColorsPrimaryHeaderNavLinkHover: '',
    themeColorsPrimaryHeaderNavLinkActive: '',
    themeColorsPrimaryHeaderNavLinkBackground: '',
    themeColorsPrimaryHeaderNavLinkHoverBackground: '',
    themeColorsPrimaryHeaderNavLinkActiveBackground: '',
    themeColorsPrimaryBackgroundColor: '#171717',
    themeColorsPrimaryBackgroundColorSecondary: '#171717',
    themeColorsPrimaryTextColor: '#FFFFFF',
    themeColorsPrimaryTextColorSecondary: '#FFFFFF',
    themeColorsPrimaryHeroQuoteTextColor: '#FFFFFF',
    themeColorsPrimaryBorderDividerColor: '',
    themeColorsPrimaryButtonBackgroundColor: '#0052cc',
    themeColorsPrimaryButtonBackgroundColorHover: '#1A63D1',
    themeColorsPrimaryButtonTextColor: '#FFFFFF',
    themeColorsPrimaryButtonTextColorHover: '#FFFFFF',
    themeColorsSecondaryHeaderNavLink: '',
    themeColorsSecondaryHeaderNavLinkHover: '',
    themeColorsSecondaryHeaderNavLinkActive: '',
    themeColorsSecondaryHeaderNavLinkBackground: '',
    themeColorsSecondaryHeaderNavLinkHoverBackground: '',
    themeColorsSecondaryHeaderNavLinkActiveBackground: '',
    themeColorsSecondaryBackgroundColor: '#FFFFFF',
    themeColorsSecondaryBackgroundColorSecondary: '#FFFFFF',
    themeColorsSecondaryTextColor: '#171717',
    themeColorsSecondaryTextColorSecondary: '#171717',
    themeColorsSecondaryHeroQuoteTextColor: '#171717',
    themeColorsSecondaryBorderDividerColor: '',
    themeColorsSecondaryButtonBackgroundColor: '#0052cc',
    themeColorsSecondaryButtonBackgroundColorHover: '#1A63D1',
    themeColorsSecondaryButtonTextColor: '#FFFFFF',
    themeColorsSecondaryButtonTextColorHover: '#FFFFFF',
    themeColorsTertiaryHeaderNavLink: '',
    themeColorsTertiaryHeaderNavLinkHover: '',
    themeColorsTertiaryHeaderNavLinkActive: '',
    themeColorsTertiaryHeaderNavLinkBackground: '',
    themeColorsTertiaryHeaderNavLinkHoverBackground: '',
    themeColorsTertiaryHeaderNavLinkActiveBackground: '',
    themeColorsTertiaryBackgroundColor: '#0052cc',
    themeColorsTertiaryBackgroundColorSecondary: '#0052cc',
    themeColorsTertiaryTextColor: '#FFFFFF',
    themeColorsTertiaryTextColorSecondary: '#FFFFFF',
    themeColorsTertiaryHeroQuoteTextColor: '#FFFFFF',
    themeColorsTertiaryBorderDividerColor: '',
    themeColorsTertiaryButtonBackgroundColor: '#FFFFFF',
    themeColorsTertiaryButtonBackgroundColorHover: '#E5E5E5',
    themeColorsTertiaryButtonTextColor: '#2068D3',
    themeColorsTertiaryButtonTextColorHover: '#2068D3',
    lightTheme: {
        actionPrimaryText: '#FFFFFF',
        actionPrimaryTextHover: '#FFFFFF',
        actionPrimaryBackground: '#0052cc',
        actionPrimaryBackgroundHover: '#000000',
        actionPrimaryBorder: 'transparent',
        actionPrimaryBorderHover: 'transparent',
        actionSecondaryText: '#FFFFFF',
        actionSecondaryTextHover: '#FFFFFF',
        actionSecondaryBackground: '#6A798C',
        actionSecondaryBackgroundHover: '#0052cc',
        actionSecondaryBorder: 'transparent',
        actionSecondaryBorderHover: 'transparent',
        actionTertiaryText: '#FFFFFF',
        actionTertiaryTextHover: '#FFFFFF',
        actionTertiaryBackground: '#A1A1A1',
        actionTertiaryBackgroundHover: '#6A798C',
        actionTertiaryBorder: 'transparent',
        actionTertiaryBorderHover: 'transparent',
        actionMonochromeText: '#FFFFFF',
        actionMonochromeBackground: '#000000',
        actionMonochromeBackgroundHover: '#727272',
        actionMonochromeBorder: 'transparent',
        actionMonochromeBorderHover: 'transparent',
        actionSecondaryWhiteText: '#FFFFFF',
        actionSecondaryWhiteTextHover: '#FFFFFF',
        actionSecondaryWhiteBackground: '#0052cc',
        actionSecondaryWhiteBackgroundHover: '#0052cc',
        actionSecondaryWhiteBorder: 'transparent',
        actionSecondaryWhiteBorderHover: 'transparent',
        actionSecondaryBlackText: '#FFFFFF',
        actionSecondaryBlackTextHover: '#FFFFFF',
        actionSecondaryBlackBackground: '#000000',
        actionSecondaryBlackBackgroundHover: '#181818',
        actionSecondaryBlackBorder: 'transparent',
        actionSecondaryBlackBorderHover: 'transparent',
        actionOutlineText: '#000000',
        actionOutlineTextHover: '#000000',
        actionOutlineBackground: 'transparent',
        actionOutlineBackgroundHover: 'transparent',
        actionOutlineBorder: '#000000',
        actionOutlineBorderHover: '#999999',
        actionTextLink: '#000000',
        actionTextLinkHover: '#181818',
        actionTextLinkBorder: '#E3E3E3',
        actionHeadlineLink: '#000000',
        actionHeadlineLinkHover: '#181818',
        actionNavLink: '#000000',
        actionNavLinkBackground: '#FFFFFF',
        actionNavLinkHover: '#0052cc',
        actionNavLinkActive: '#0052cc',
        actionNavLinkHoverBackground: '#FFFFFF',
        actionDisabledText: '#FFFFFF',
        actionDisabledBackground: '#6A798C',
        actionDisabledBackgroundHover: '#6A798C',
        actionDisabledBorder: '#6A798C',
        actionDisabledBorderHover: '#6A798C',
        actionRadioSquareBackground: '#FFFFFF',
        actionRadioSquareActiveBackground: '#000000',
        actionRadioSquareColor: '#000000',
        actionRadioSquareActiveColor: '',
        textTitle: '#121212',
        textHeadline: '#000000',
        textSubHeadline: '#000000',
        textTitleOverlay: '#FFFFFF',
        textBody: '#000000',
        textCaption: '#000000',
        textPrice: '#000000',
        formInputLabelText: '#000000',
        formInputFocusLabelText: '#000000',
        formInputInputText: '#000000',
        formInputInputTextDisabled: '#999999',
        formInputPlaceholderText: '#4F4F4F',
        formInputBorder: '#000000',
        formInputBorderFocus: '#0052cc',
        formInputBorderError: '#c23934',
        formInputCheckbox: '#0073BE',
        formInputBackground: '#FFFFFF',
        formInputBackgroundDisabled: '#F9F9F9',
        formRadioEnabled: '#000000',
        formRadioDisabled: '#6A798C',
        formRadioBorderDisabled: '#E3E3E3',
        formRadioBorderEnabled: '#6A798C',
        formRadioBorderActive: '#000000',
        formListItemBackground: '#F3F3F3',
        formListItemBackgroundHover: '#F3F3F3',
        formSliderVerticalBars: '#0052cc',
        formSliderHandleBackground: '#FFFFFF',
        formSliderHandleBorder: '#000000',
        formSliderProgressBar: '#000000',
        formStatusErrorLight: '#FEF3F2',
        formStatusErrorMain: '#c23934',
        formStatusErrorDark: '#AE201A',
        formStatusSuccessLight: '#F0FDF5',
        formStatusSuccessMain: '#21A153',
        formStatusSuccessDark: '#21A153',
        formStatusWarningLight: '#FEFCE9',
        formStatusWarningMain: '#ffb75d',
        formStatusWarningDark: '#975A12',
        formStatusInfoLight: '#EFF6FF',
        formStatusInfoMain: '#0073BE',
        formStatusInfoDark: '#1244CA',
        headerBackground: '#FFFFFF',
        headerStickyBorderBottom: 'rgba(227, 227, 227, 0.1)',
        headerStickyBoxShadow: 'rgba(0, 0, 0, 0.03)',
        headerStickyBoxShadowNoNotification: 'rgba(0, 0, 0, 0.25)',
        headerStickyBackground: '#FFFFFF',
        headerStickyBackgroundScrolled: '#FFFFFF',
        headerStickyBorderBottomScrolled: '#E3E3E3',
        headerSearchInputBorder: '#6A798C',
        headerMegamenu: '#F9F9F9',
        headerPlpSidebarTitle: '#000000',
        footerBackground: '#FFFFFF',
        footerTextColor: '#000000',
        footerHeadingColor: '',
        tableThead: '#F9F9F9',
        tableTh: '#F9F9F9',
        tableTd: '#FFFFFF',
        iconPrimary: '#000000',
        iconSecondary: '#727272',
        iconTertiary: '#000000',
        iconLoader: '#FFFFFF',
        iconRoundedBackground: '#F3F3F3',
        iconRoundedIcon: '#000000',
        iconRoundedBorder: '#000000',
        iconRoundedActiveBackground: '#000000',
        iconRoundedActiveIcon: '#F3F3F3',
        iconRoundedActiveBorder: '#000000',
        logo: '#000000',
        borderDivider1: '#F3F3F3',
        borderDivider2: '#0052cc',
        pillActiveTextColor: '#FFFFFF',
        pillActiveTextColorHover: '#FFFFFF',
        pillActiveBorderColor: '#000000',
        pillActiveBorderColorHover: '#000000',
        pillActiveBackground: '#000000',
        pillActiveBackgroundHover: '#000000',
        pillDefaultTextColor: '#000000',
        pillDefaultTextColorHover: '#000000',
        pillDefaultBorderColor: '#000000',
        pillDefaultBorderColorHover: '#999999',
        pillDefaultBackground: '#FFFFFF',
        pillDefaultBackgroundHover: '#FFFFFF',
        pillBadgeBackground: '#FFFFFF',
        pillBadgeBorderColorHover: '#999999',
        wrapperBody: '#FFFFFF',
        wrapperPublication: 'undefined',
        wrapperCategory: 'undefined',
        wrapperProduct: 'undefined',
        wrapperTileColor: 'undefined',
        wrapperTileColorHover: 'undefined',
        wrapperSection: '#FFFFFF',
        wrapperBodyHover: '#F9F9F9',
        wrapperWell: '#FFFFFF',
        wrapperCard: '#F3F3F3',
        wrapperCardHover: '#F9F9F9',
        wrapperCardGradientColor: '#F3F3F3',
        wrapperCardGradientColorHover: '#F9F9F9',
        wrapperCardBorderColor: 'transparent',
        wrapperModal: '#999999',
        wrapperBanner: '#FFFFFF',
        wrapperBannerBorder: '#FFFFFF',
        wrapperUsp: '#0052cc',
        wrapperUspColor: '#FFFFFF',
        oBikeComparisonCompareButtonBackground: '#F3F3F3',
        oBikeComparisonCompareButtonBorderColor: '#6A798C',
        uListItemHighlightColor: '#333333',
        uListItemTextColorHover: '#999999',
        uCarouselBackgroundColor: '#FFFFFF',
        uPullUpToggleBackground: '#000000',
        uNewsletterTitleColor: '#0052cc',
        uKeyFeatureListBackgroundColor: 'unset',
        fallbackWhite: '#FFFFFF',
        fallbackGrey50: '#F9F9F9',
        fallbackGrey100: '#F3F3F3',
        fallbackGrey200: '#E3E3E3',
        fallbackGrey300: '#6A798C',
        fallbackGrey400: '#999999',
        fallbackGrey500: '#727272',
        fallbackGrey600: '#4F4F4F',
        fallbackGrey700: '#000000',
        fallbackGrey750: '#1E1E1E',
        fallbackGrey800: '#181818',
        fallbackGrey900: '#121212',
        fallbackBlack: '#000000',
    },
    darkTheme: {
        actionPrimaryText: '#FFFFFF',
        actionPrimaryTextHover: '#FFFFFF',
        actionPrimaryBackground: '#0052cc',
        actionPrimaryBackgroundHover: '#00F0FF',
        actionPrimaryBorder: 'transparent',
        actionPrimaryBorderHover: 'transparent',
        actionSecondaryText: '#FFFFFF',
        actionSecondaryTextHover: '#FFFFFF',
        actionSecondaryBackground: '#6A798C',
        actionSecondaryBackgroundHover: '#007B4D',
        actionSecondaryBorder: 'transparent',
        actionSecondaryBorderHover: 'transparent',
        actionTertiaryText: '#FFFFFF',
        actionTertiaryTextHover: '#FFFFFF',
        actionTertiaryBackground: '#A1A1A1',
        actionTertiaryBackgroundHover: '#D0A045',
        actionTertiaryBorder: 'transparent',
        actionTertiaryBorderHover: 'transparent',
        actionMonochromeText: '#FFFFFF',
        actionMonochromeBackground: '#000000',
        actionMonochromeBackgroundHover: '#F9F9F9',
        actionMonochromeBorder: 'transparent',
        actionMonochromeBorderHover: 'transparent',
        actionSecondaryWhiteText: '#000000',
        actionSecondaryWhiteTextHover: '#000000',
        actionSecondaryWhiteBackground: '#FFFFFF',
        actionSecondaryWhiteBackgroundHover: '#F9F9F9',
        actionSecondaryWhiteBorder: 'transparent',
        actionSecondaryWhiteBorderHover: 'transparent',
        actionSecondaryBlackText: '#000000',
        actionSecondaryBlackTextHover: '#000000',
        actionSecondaryBlackBackground: '#FFFFFF',
        actionSecondaryBlackBackgroundHover: '#F9F9F9',
        actionSecondaryBlackBorder: 'transparent',
        actionSecondaryBlackBorderHover: 'transparent',
        actionOutlineText: '#FFFFFF',
        actionOutlineTextHover: '#FFFFFF',
        actionOutlineBackground: 'transparent',
        actionOutlineBackgroundHover: 'transparent',
        actionOutlineBorder: '#6A798C',
        actionOutlineBorderHover: '#999999',
        actionTextLink: '#FFFFFF',
        actionTextLinkHover: '#F3F3F3',
        actionTextLinkBorder: '#F3F3F3',
        actionHeadlineLink: '#FFFFFF',
        actionHeadlineLinkHover: '#F3F3F3',
        actionNavLink: '#000000',
        actionNavLinkBackground: '#000000',
        actionNavLinkHover: '#0052cc',
        actionNavLinkHoverBackground: '#FFFFFF',
        actionNavLinkActive: '#0052cc',
        actionNavLinkActiveBackground: '#FFFFFF',
        actionDisabledText: '#FFFFFF',
        actionDisabledBackground: '#6A798C',
        actionDisabledBackgroundHover: '#6A798C',
        actionDisabledBorder: '#6A798C',
        actionDisabledBorderHover: '#6A798C',
        actionRadioSquareBackground: '#FFFFFF',
        actionRadioSquareActiveBackground: '#000000',
        actionRadioSquareColor: '#000000',
        actionRadioSquareActiveColor: '',
        textTitle: '#FFFFFF',
        textHeadline: '#FFFFFF',
        textSubHeadline: '#F9F9F9',
        textTitleOverlay: '#FFFFFF',
        textBody: '#FFFFFF',
        textCaption: '#F9F9F9',
        textPrice: '#F9F9F9',
        formInputLabelText: '#999999',
        formInputFocusLabelText: '#999999',
        formInputInputText: '#FFFFFF',
        formInputPlaceholderText: '#E3E3E3',
        formInputBorder: '#999999',
        formInputBorderFocus: '#999999',
        formInputBorderError: '#c23934',
        formInputCheckbox: '#0073BE',
        formInputBackground: '#121212',
        formInputBackgroundSecondary: '#333333',
        formInputBorderSecondary: '#333333',
        formInputBackgroundDisabled: '#333333',
        formRadioEnabled: '#000000',
        formRadioDisabled: '#6A798C',
        formRadioBorderDisabled: '#E3E3E3',
        formRadioBorderEnabled: '#6A798C',
        formRadioBorderActive: '#000000',
        formListItemBackground: '#181818',
        formListItemBackgroundHover: '#181818',
        formSliderVerticalBars: '#6A798C',
        formSliderHandleBackground: '#FFFFFF',
        formSliderHandleBorder: '#6A798C',
        formSliderProgressBar: '#00F0FF',
        formStatusErrorLight: '#FEF3F2',
        formStatusErrorMain: '#c23934',
        formStatusErrorDark: '#AE201A',
        formStatusSuccessLight: '#F0FDF5',
        formStatusSuccessMain: '#21A153',
        formStatusSuccessDark: '#21A153',
        formStatusWarningLight: '#FEFCE9',
        formStatusWarningMain: '#ffb75d',
        formStatusWarningDark: '#975A12',
        formStatusInfoLight: '#EFF6FF',
        formStatusInfoMain: '#0073BE',
        formStatusInfoDark: '#1244CA',
        headerBackground: '#FFFFFF',
        headerStickyBorderBottom: 'rgba(227, 227, 227, 0.1)',
        headerStickyBoxShadow: 'rgba(0, 0, 0, 0.03)',
        headerStickyBoxShadowNoNotification: 'rgba(0, 0, 0, 0.25)',
        headerStickyBackground: '#FFFFFF',
        headerStickyBackgroundScrolled: '#FFFFFF',
        headerStickyBorderBottomScrolled: '#E3E3E3',
        headerSearchInputBorder: '#E3E3E3',
        headerMegamenu: '#F9F9F9',
        headerPlpSidebarTitle: '#FFFFFF',
        footerBackground: '#121212',
        footerTextColor: '#FFFFFF',
        footerHeadingColor: '',
        tableThead: '#F9F9F9',
        tableTh: '#F9F9F9',
        tableTd: '#121212',
        iconPrimary: '#FFFFFF',
        iconSecondary: '#727272',
        iconTertiary: '#4F4F4F',
        iconLoader: '#FFFFFF',
        iconRoundedBackground: '#181818',
        iconRoundedIcon: '#FFFFFF',
        iconRoundedBorder: '#FFFFFF',
        iconRoundedActiveBackground: '#FFFFFF',
        iconRoundedActiveIcon: '#181818',
        iconRoundedActiveBorder: '#FFFFFF',
        logo: '#000000',
        borderDivider1: '#4F4F4F',
        borderDivider2: '#E3E3E3',
        pillActiveTextColor: '#181818',
        pillActiveTextColorHover: '#181818',
        pillActiveBorderColor: '#FFFFFF',
        pillActiveBorderColorHover: '#FFFFFF',
        pillActiveBackground: '#FFFFFF',
        pillActiveBackgroundHover: '#FFFFFF',
        pillDefaultTextColor: '#FFFFFF',
        pillDefaultTextColorHover: '#FFFFFF',
        pillDefaultBorderColor: '#000000',
        pillDefaultBorderColorHover: '#000000',
        pillDefaultBackground: '#000000',
        pillDefaultBackgroundHover: '#000000',
        pillBadgeBackground: '#FFFFFF',
        pillBadgeBorderColorHover: '#000000',
        wrapperBody: '#121212',
        wrapperPublication: 'undefined',
        wrapperCategory: 'undefined',
        wrapperProduct: 'undefined',
        wrapperTileColor: 'undefined',
        wrapperTileColorHover: 'undefined',
        wrapperSection: '#121212',
        wrapperBodyHover: '#1E1E1E',
        wrapperWell: '#000000',
        wrapperCard: '#181818',
        wrapperCardHover: '#121212',
        wrapperCardGradientColor: '#181818',
        wrapperCardGradientColorHover: '#121212',
        wrapperCardBorderColor: 'transparent',
        wrapperModal: '#181818',
        wrapperBanner: '#FFFFFF',
        wrapperBannerBorder: '#FFFFFF',
        wrapperUsp: '#0052cc',
        wrapperUspColor: '#FFFFFF',
        oBikeComparisonCompareButtonBackground: '#F8F8F8',
        oBikeComparisonCompareButtonBorderColor: '#6A798C',
        uListItemHighlightColor: '#FFFFFF',
        uListItemTextColorHover: '#333333',
        uCarouselBackgroundColor: '#FFFFFF',
        uPullUpToggleBackground: '#000000',
        uNewsletterTitleColor: '#0052cc',
        uKeyFeatureListBackgroundColor: 'unset',
        fallbackWhite: '#FFFFFF',
        fallbackGrey50: '#F9F9F9',
        fallbackGrey100: '#F3F3F3',
        fallbackGrey200: '#E3E3E3',
        fallbackGrey300: '#6A798C',
        fallbackGrey400: '#999999',
        fallbackGrey500: '#727272',
        fallbackGrey600: '#4F4F4F',
        fallbackGrey700: '#000000',
        fallbackGrey750: '#1E1E1E',
        fallbackGrey800: '#181818',
        fallbackGrey900: '#121212',
        fallbackBlack: '#000000',
    },
};
